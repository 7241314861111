<template>
    <div id="News">
        <section id="news-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">お知らせ</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="news-title" class="title-section-style hide-mb">
            <h2 class="title f29">お知らせ</h2>
        </section>
        <section id="news-content">
            <article class="w80 w80-mb">
                <h3 class="f14 f12-mb">{{ news[index].date }}</h3>
                <h2 class="f29 f22-mb">{{ news[index].title }}</h2>
                <img v-lazy="news[index].imageURL" alt="activity image" />
                <p class="f14 f12-mb" v-html="wrap(news[index].article)"></p>
            </article>
        </section>
        <section id="news-gallery">
            <NewsGallery></NewsGallery>
        </section>
    </div>
</template>
<script>
import { computed, defineAsyncComponent } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
const NewsGallery = defineAsyncComponent(() =>
    import('@/components/NewsGallery.vue')
)
import getNewsData from '@/data/getNewsData.js'
export default {
    name: 'News',
    components: { NewsGallery },
    setup() {
        const index = computed(() => useRoute().params.newsId)
        const { news } = getNewsData()
        onBeforeRouteUpdate(() => {
            scrollToElement('#news-content')
        })
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            news,
            index,
        }
    },
}
</script>
<style lang="scss" scoped>
#news-content {
    img {
        display: block;
        margin: 30px 0;
        max-width: 100%;
    }
    @media screen and (min-width: 768px) {
        background-color: $minorGold;
        padding: 80px 0;
        article {
            padding: 5%;
            box-sizing: border-box;
            background-color: $white;
        }
    }
    @media screen and(max-width:767px) {
        article {
            padding: 10% 0 20%;
        }
    }
}
#news-gallery {
    @media screen and (min-width: 768px) {
        background-color: $minorGold;
    }
}
</style>
